<template>
  <div>

    <div class="videoBanner"
         style="position: absolute; top: 0; width: 100%; height: 100%; background: #000000">

      <div class="videoHeader">
        <div class="backBox"
             @click="goBack">
          <div style="display: flex">
            <img src="../../../assets/images/back_icon.png"
                 alt="" />
          </div>
          <div class="back_text">返回学习中心</div>
        </div>
        <div class="courseText">{{ videoInfo.chapterName || '' }}</div>
      </div>

      <div class="bannerBox">
        <div class="videoBox"
             style="position: relative;">
          <div ref="player"
               class="videoBox"
               id="videoPlayer1"
               @ended="endVideo(1)"
               @timeupdate="timeupdate"></div>
          <img style="position: absolute;top: 10px;right:30px;z-index: 99999;"
               src="../../../assets/indexImg/video_new1.png"
               alt="">
        </div>
        <div class="tips">
          <div class="tipsTitle">
            温馨提示
            <div></div>
          </div>

          <div class="margin25">1.此学习为提升性学习，不记录相关学时，若需继续教育学时请到报名选课页面进行报名；</div>
          <div class="margin25">2.如果视频无法播放，推荐使用360浏览器极速模式；</div>
          <div class="margin25">3.遇到任何学习问题都可拨打客服电话咨询；</div>
          <div class="margin25">4.如您需要发票请到缴费订单管理界面申领电子发票。</div>
        </div>

        <div class="directory">
          <div class="tipsTitle">
            目录
            <div></div>
          </div>

          <div v-for="(item) in chapterList"
               :key="item.ID">
            <div class="listBox_1">
              <div class="ml8"
                   style="color: #2878ff;"
                   :title="item.C_NAME">{{ item.C_NAME }}</div>
              <div class="mar38"></div>

            </div>
            <div>
              <div class="listBox_1"
                   style="margin-top: 0;"
                   v-for="(data) in item.chapterList"
                   :key="data.ID"
                   @click="videoChange(data,1)">
                <div style="display: flex; align-items: center"
                     v-if="videoInfo.courseId == data.ID">
                  <div class="play">当前播放</div>
                </div>
                <div class="ml8"
                     :title="data.CHAPTER_TITLE">{{ data.CHAPTER_TITLE }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import 'xgplayer';
import hlsjsPlayer from 'xgplayer-hls.js';
import Player from 'xgplayer';
import { toZYKStudy, toZYKStudyMain } from '@/api/study.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      TMvalid: false, //是否需要验证码，false不需要
      dialogVisible: false, //验证码弹框
      codeNum: '', //验证码结果
      configKey: '', //tm平台需要的key
      codeImg: '', //验证码图片
      codeId: '', //提交验证码必要参数
      chapterid: '', //提交验证码必要参数
      tmId: '', //提交验证码必要参数
      coursePlayTimeList: [],
      isNeedVerify: '', //是否需要验证
      codeResTime: '', //验证时间
      codeIndex: '',
      userId: '',
      getVerCodeTime: 1, //1分钟
      everyProVideo: 15, //假定视频总长度
      isVerify_0: false, //
      verifyChapter: '',
      verCodeCount: 0,
      codeStatus: false, //验证码检查状态
      isGetTmVerCodeByVideoPlayTimer: null, //定时器
      options: {
        autoplay: true, // 设置自动播放
        muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true // 显示播放的控件
      },
      player: null,
      queryPams: {},
      chapterList: [], //目录
      videoInfo: {}, //当前播放数据
      lessonstatus: '', //学习状态0
      videoTime: 0,
      timer: null,
      allInt: false, //视频是否已完成 false未完成 true已完成
      className: '1',
      t: false, //是pc还是移动端
      jinDuId: '', //动态渲染当前播放视频进度
      foj: '', //进度
      isExam: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goBack() {
      this.$router.back();
    },
    videoChange(item, t) {
      console.log(item);
      let data = {
        launch: item.LAUNCH
      };
      this.videoInfo = {
        courseId: item.ID,
        chapterName: item.CHAPTER_TITLE
      };
      this.getVideo(data, t);
      window.scrollTo(0, 0);
    },
    timeupdate(e) {},
    //视频结束事件
    endVideo(t) {},
    getData(t) {
      this.queryPams.courseId = this.$route.query.courseId;
      toZYKStudy(this.queryPams).then(res => {
        this.videoInfo = res.data.data;
        this.getVideo(res.data.data, t);
      });
      toZYKStudyMain({}).then(res => {
        console.log(res.data.data.courseList);
        this.chapterList = res.data.data.courseList;
        this.videoChange(this.chapterList[0].chapterList[0], 1);
      });
    },
    getVideo(videoUrl) {
      if (this.player !== null) {
        this.player.destroy('videoPlayer1');
      }
      //判断视频源是否是m3u8的格式
      //正常课程
      if (/\.m3u8$/.test(videoUrl.launch)) {
        //判断视频源是否是m3u8的格式
        this.player = new hlsjsPlayer({
          id: 'videoPlayer1',
          autoplay: true,
          playsinline: true,
          url: videoUrl.launch
        });
      } else {
        let url = videoUrl.launch + '.mp4';
        this.player = new Player({
          id: 'videoPlayer1',
          autoplay: true,
          playsinline: true,
          url: url
        });
      }
      this.player.play();
      let _this = this;
      // _this.player.play();
      _this.player.on('ended', function () {
        _this.endVideo(1);
      });
      // this.player.on('error', function () {});
      // this.player.on('currentTime', function () {});
      // this.player.on('seeked', function () {});
      // this.player.on('ratechange', function () {});
      _this.player.on('timeupdate', function () {
        _this.timeupdate();
      });
      // this.player.on('volumechange', function () {});
      // this.player.on('stalled', function () {});
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeDestroy() {
    // if (this.player) {
    //   this.player.destroy(); //重置videojs的内部状态并移除dom
    // }
  }
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */

.videoHeader {
  height: 60px;
  background: #1c2127;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.27);
  opacity: 1;
  border-radius: 0px;
  padding: 15px 30px;
  display: flex;
  .backBox {
    width: 140px;
    height: 30px;
    background: #438ce7;
    opacity: 1;
    border-radius: 15px;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
    }
    .back_text {
      margin-left: 8px;
      font-size: 14px;
    }
  }
  .courseText {
    margin-left: 12px;
    color: #bfc5c9;
    display: flex;
    align-items: center;
  }
}

.bannerBox {
  padding: 0 0 0 30px;
  display: flex;
  .videoBox {
    margin-top: 20px !important;
    width: 1170px !important;
    height: 800px !important;
    opacity: 1;
    border-radius: 0px;
  }

  .tips {
    margin-top: 20px;
    width: 262px;
    height: 800px;
    background: #1c2127;
    margin-left: 20px;
    padding: 20px 30px;
    color: #bfc5c9;
    font-size: 14px;
    .tipsTitle {
      font-size: 16px;
      position: relative;
      z-index: 2;
      div {
        position: absolute;
        width: 64px;
        height: 6px;
        background: linear-gradient(270deg, rgba(21, 109, 232, 0) 0%, #156de8 100%);
        opacity: 1;
        border-radius: 0px;
        bottom: 3px;
        z-index: -1;
      }
    }
    .margin25 {
      margin-top: 25px;
    }
  }
  .directory::-webkit-scrollbar {
    width: 5px; //修改滚动条宽度
  }
  .directory::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }
  //鼠标移入样式
  .directory::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }

  .directory {
    width: 418px;
    height: 850px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #1c2127;
    margin-left: 20px;
    padding: 30px 20px;
    color: #bfc5c9;
    font-size: 14px;

    .tipsTitle {
      font-size: 16px;
      position: relative;
      z-index: 2;
      margin-bottom: 10px;
      div {
        position: absolute;
        width: 64px;
        height: 6px;
        background: linear-gradient(270deg, rgba(21, 109, 232, 0) 0%, #156de8 100%);
        opacity: 1;
        border-radius: 0px;
        bottom: 3px;
        z-index: -1;
      }
    }
    .listBox_1 {
      margin-top: 20px;
      width: 378px;
      height: 44px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
      color: #bfc5c9;
      padding: 0 1px;
      line-height: 44px;
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      .ml8 {
        width: 368px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 8px;
      }
      .mar38 {
        margin-left: 30px;
        margin-right: 10px;
      }
      .play {
        width: 66px;
        height: 24px;
        background: #438ce7;
        opacity: 1;
        border-radius: 12px;
        text-align: center;
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
}
</style>
