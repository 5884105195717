import request from '../utils/request';
//获取订单
export function toOrderList(data) {
  return request({
    url: '/userData/toOrderList.do',
    method: 'post',
    data
  });
}

//申请发票--true
export function doGenerateInvoice(data) {
  return request({
    url: '/userData/doGenerateInvoice.do',
    method: 'post',
    data
  });
}


//获取用户详情--true
export function getUser(data) {
  return request({
    url: '/userData/getUser.do',
    method: 'post',
    data
  });
}


//获取用户证书列表
export function toCertList(data) {
  return request({
    url: '/userData/toCertList.do',
    method: 'post',
    data
  });
}


//获取证书详情
export function getCertDetail(data) {
  return request({
    url: '/userData/getCertDetail.do',
    method: 'post',
    data
  });
}

//证书申请页面接口
export function toApplyCert(data) {
  return request({
    url: '/userData/toApplyCert.do',
    method: 'post',
    data
  });
}


//证书批量申请页面接口
export function toApplyCertMore(data) {
  return request({
    url: '/userData/toApplyCertMore.do',
    method: 'post',
    data
  });
}


//验证申请证书请求接口
export function toGetCert(data) {
  return request({
    url: '/userData/toGetCert.do',
    method: 'post',
    data
  });
}

//申请证书请求接口
export function toGenExpress(data) {
  return request({
    url: '/userData/toGenExpress.do',
    method: 'post',
    data
  });
}

//验证申请证书请求接口（批量）
export function toGetCertBatch(data) {
  return request({
    url: '/userData/toGetCertBatch.do',
    method: 'post',
    data
  });
}

//取消自领接口
export function toCancelZL(data) {
  return request({
    url: '/userData/toCancelZL.do',
    method: 'post',
    data
  });
}

//取消代领接口
export function toCancelDL(data) {
  return request({
    url: '/userData/toCancelDL.do',
    method: 'post',
    data
  });
}

//请人代领接口
export function toPleaseLead(data) {
  return request({
    url: '/userData/toPleaseLead.do',
    method: 'post',
    data
  });
}

//代领接口
export function doPleaseLead(data) {
  return request({
    url: '/userData/doPleaseLead.do',
    method: 'post',
    data
  });
}

//订单详情
export function toOrderDetail(data) {
  return request({
    url: '/userData/toOrderDetail.do',
    method: 'post',
    data
  });
}

//取消订单
export function doCancelOrder(data) {
  return request({
    url: '/userData/doCancelOrder.do',
    method: 'post',
    data
  });
}

//删除订单
export function doDeleteOrder(data) {
  return request({
    url: '/userData/doDeleteOrder.do',
    method: 'post',
    data
  });
}

//修改手机号码
export function doUpdateMobile(data) {
  return request({
    url: '/userData/doUpdateMobile.do',
    method: 'post',
    data
  });
}

//修改密码
export function doUpdateUserPasswd(data) {
  return request({
    url: '/userData/doUpdateUserPasswd.do',
    method: 'post',
    data
  });
}

//获取用户地址列表
export function getUserAddressList(data) {
  return request({
    url: '/userData/getUserAddressList.do',
    method: 'post',
    data
  });
}

//新增地址
export function addUserAddress(data) {
  return request({
    url: '/userData/addUserAddress.do',
    method: 'post',
    data
  });
}

//在学进度
export function toMyClassList(data) {
  return request({
    url: '/learning/toMemberCenter.do',
    method: 'post',
    data
  });
}

//分类列表
export function getCodeByParent(data) {
  return request({
    url: '/userData/getMemberXL.do',
    method: 'post',
    data
  });
}


//获取职称
export function getTitleList(data) {
  return request({
    url: '/learning/getTitleList.do',
    method: 'post',
    data
  });
}

//修改用户资料
export function updateUser(data) {
  return request({
    url: '/userData/updateUser.do',
    method: 'post',
    data
  });
}

//获取验证码
export function sendDx2(data) {
  return request({
    url: '/web/sendDx2.do',
    method: 'post',
    data
  });
}

//修改手机下一步
export function checkCode(data) {
  return request({
    url: '/userData/checkCode.do',
    method: 'post',
    data
  });
}



//是否考试
export function checkUserQuiz(data) {
  return request({
    url: '/learning/checkUserQuiz.do',
    method: 'post',
    data
  });
}

//获取证书类型
export function toGetCertType(data) {
  return request({
    url: '/userData/toGetCertType.do',
    method: 'post',
    data
  });
}


//判断token是否可用
export function checkToken(data) {
  return request({
    url: '/user/checkToken.html',
    method: 'get',
    params:data
  });
}

//我的课程资源库
export function toMyZYKCourseList(data) {
  return request({
    url: '/learning/toMyZYKCourseList.do',
    method: 'post',
    data:data
  });
}

//获取视频地址
export function toZYKStudy(data) {
  return request({
    url: '/learning/toZYKStudy.do',
    method: 'post',
    data:data
  });
}

//获取视频目录
export function toZYKStudyMain(data) {
  return request({
    url: '/learning/toZYKStudyMain.do',
    method: 'post',
    data:data
  });
}









